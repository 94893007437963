$yellow : #f9cf03;
$light-black : #1d1e2a;
$grey-border : #dedede;
$black : #1d1e2a;
$white : #fff;

$break_2 : 999px;
$break_3 : 749px;
$break_4 : 520px;

$grid_0 : 940px;
$grid_3 : 470px;

$regular_font : 300;
$bold_font : 400;

$acagiBold : "Akagi Pro W00 Bold";

@media (max-width: 480px) {
    /*#header{
        display:none!important;
    }*/
    
    
    
    
    /* Mobile menu*/
    
    #mobile-navigation{
        display: block;
    }
    #mobile-navigation{
        margin-bottom: 0px;
        background: transparent;
        border: 0;
        position: fixed;
        top: 0;
        z-index: 1000;
        width: 100%;
        height:40px;
        
        
        #mobile-logo-container{
            position: relative;
            z-index:1003;
            a{
                height: 80px;
                width: 80px;
                background-image: url('../svg/own-logo/gl-stroke-bianco.svg');
                background-position: 0px -10px;
                background-repeat: no-repeat;
                background-size: 75px 75px;
                display: block;

                -webkit-transition: all 0.50s ease-in-out;
                /* Safari 3.1 to 6.0 */
                transition: all 0.50s;
            }
        }
        
        #mobile-burger-container{
            
            float: right;
            z-index:1004;
        }
        

        .burger:focus,
        .burger:hover,
        .burger{
            margin-top: 0px;
            margin-bottom: 20px;
            background: #fff;
            margin-right: 30px;
        }
        
        .navbar-toggle-menu {
            cursor: pointer;
            position: relative;
            width: 35px;
            height: 35px;
            padding: 8px 8px;
            border: 0px solid transparent;
        }
        .navbar-toggle-menu span {
            width: 18px;
            height: 2px;
            background-color: $black;
            transition: all 0.3s ease;
        }
        .navbar-toggle-menu span:nth-child(2) {
            top: 48%;
            opacity: 1;
        }
        .navbar-toggle-menu span:last-child {
            bottom: 0;
        }
        .navbar-toggle-menu.is-open span:first-child {
            transform: rotate(45deg);
            transform-origin: left bottom;
            -webkit-transform: rotate(45deg); /* Safari */
            -webkit-transform-origin: left bottom;
        }
        .navbar-toggle-menu.is-open span:nth-child(2) {
            opacity: 0;
        }
        .navbar-toggle-menu.is-open span:last-child {
            transform: rotate(-45deg);
            transform-origin: 20%;
            -webkit-transform: rotate(-45deg); /* Safari */
            -webkit-transform-origin: 20%;

            margin-top: 2px;
        }
        
        #bs-mobile-navbar-collapse{
            position: fixed;
            top: 0;
            left: 100%;
            width: 100%;
            background: #fff;
            border-bottom:2px solid $grey-border;
            z-index: -1;
            padding-bottom:30px;
            opacity: 0;
            
            
            -webkit-transition: all 0.50s ease-in-out;
            /* Safari 3.1 to 6.0 */
            transition: all 0.50s;
            
            ul{
                margin: 60px 30px 0 0;
                list-style:none;
                
                li{
                    text-align: right;
                    font-size:20px;
                    a{
                        color:black!important;
                        &:first-child{
                            padding-top: 0;
                        }
                        
                        &.active,
                        &:hover{
                            font-family: $acagiBold; 
                        }
                    }
                }
            }
            
            &.in{
                opacity: 1;
                left: 0;
            }
        }
    }
}



//land

@media (min-width: 481px) and (max-width: 740px) {
    .container-slider{
        .slider{
            h1{
                color: #1d1f29;
                font-size: 50px!important;
                line-height: 50px;
                margin: 40px 0 0 0;
                z-index: 100;
                position: relative;
            }
            
            .slide1{
                .left{
                    img{
                        margin-top: 0;
                        width: 75%;
                    }
                }
            }
            .slide2{
                .right{
                    position: absolute;
                    right: -160px;
                    top: 0px;
                    img{
                        width: 395px!important;
                    }
                }
            }
            .slide3{
                .right{
                    position: absolute;
                    right: -250px;
                    top: 30px;
                    
                    p{
                        img{
                            width: 300px!important;
                            
                            @media (min-width: 481px) and (max-width: 570px) {
                                width: 240px!important;
                            }
                        }
                    }
                }
            }
            .slide4{
                
                .left{
                    h2{
                        width: 72%;
                    }
                    
                }
                .back-image{
                    margin-top: -66% !important;
                    margin-right: -50%;
                    @media (min-width: 481px) and (max-width: 570px) {
                        margin-top: -62% !important;
                    }
                }
            }
            .slide5{
                .right{
                    position: absolute;
                    right: -250px;
                    top: 30px;
                    
                    p{
                        img{
                            width: 300px!important;
                        }
                    }
                }
            }

        }
    }
    
    .content-spot{
        .svg-container{
            text-align:center;
            svg{
                width: 62%; //30%;
                
            }
        }

    }
    
}


//iphone6 
@media (min-width: 481px) and (max-width: 668px) {
    
    .footer-slide{
        left: -17%!important;
    } 
    .quartaSlideText{
        font-size: 20px!important;
    }
    
    .slide4{
        .back-image{
            background-size: auto 330px;
            margin-right: -20%!important;
        }
    } 

}

//iphone5
@media (min-width: 481px) and (max-width: 570px) {
    .slide1{
        .left{
            img{
                margin-top: 0;
                width: 60%!important;
            }
        }
    }

    .footer-slide{
        left: -5%!important;
        p{
            font-size: 15px!important;
        }
    } 
    .quartaSlideText{
        font-size: 20px!important;
    }
    
    .slide2{
        .right{
            img{
                width: 350px!important;
                position: absolute!important;
                right: 72px!important;
            }
        }
    } 
    
    .slide4{
        .back-image{
            background-size: auto 330px;
            margin-right: -20%!important;
        }
    } 

}